:root { 
  --black: #000000;
  --eerie-black: #1d1d1b;
  --mine-shaft: #333333;
  --smashed-pumpkin: #fb6b3e;
  --white: #ffffff;
 
  --font-size-l: 28px;
  --font-size-m: 24px;
  --font-size-s: 22px;
  --font-size-xl: 32px;
  --font-size-xs: 18px;
  --font-size-xxl: 36px;
  --font-size-xxxl: 48px;
 
  --font-family-montserrat: "Montserrat", Helvetica;
  --font-family-montserrat_alternates: "Montserrat Alternates", Helvetica;
}
