@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,300,500,600|Montserrat+Alternates:500");

html {
  scroll-behavior: smooth;
  background-color: white;
}

.screen a {
  display: contents;
  text-decoration: none;
}

.inputType {
  width: 75%;
}

:any-link {
  text-decoration: none;
}

.container-center-horizontal {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /*centers items on the line (the x-axis by default)*/
  align-items: center; /*centers items on the cross-axis (y by default)*/
}

.form-cala {
  padding: "50px";
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.carousel-container {
  background-color: transparent !important;
}

.carousel-item {
  width: 62% !important;
}

.carousel .slide img {
  height: 500px;
}

.hide-on-mobile {
  display: flex;
}

.hide-on-desktop {
  display: none;
}

@media (max-width: 820px) {
  body {
    background-image: url("https://azullimaoalado.com/img/gradiente@1x.svg");
    background-size: cover;
    background-position: 30%;
  }

  .carousel-item {
    width: 100% !important;
  }

  .carousel .slide img {
    height: 300px;
  }

  .hide-on-mobile {
    display: none;
  }

  .hide-on-desktop {
    display: flex;
  }

}

.carousel-caption-bottom {
  left: 740px !important;
  width: 38% !important;
  bottom: 380px !important;
  text-align: left !important;
  text-shadow: none !important;
  padding: 0 !important;
}

.temp_carousel_titulo {
  color: black;
  font-family: "Montserrat", Helvetica;
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  min-height: 40px;
}

.temp_carousel_tema {
  color: black;
  font-family: "Montserrat", Helvetica;
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  min-height: 50px;
}

.temp_carousel_descricao {
  color: black;
  font-family: "Montserrat", Helvetica;
  font-size: 28px;
  font-weight: 300;
  font-style: normal;
}

* {
  box-sizing: border-box;
}
